import React from "react"

// Components
import { AboutSection } from "components"

export const AboutUs = () => {
  return (
    <div className="w-full">
      <AboutSection />
    </div>
  )
}
