import React from "react"

// Router
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
} from "react-router-dom"

// Global styles
import "./index.css"

// Routes
import { AboutUs, App, ContactUs, Error, ProductDetail, Products } from "routes"

// Layout
import { RootLayout } from "theme/layout"

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <RootLayout>
          <Outlet />
        </RootLayout>
      }
      errorElement={<Error />}
    >
      <Route path="/" element={<App />} />
      <Route path="/products" element={<Products />} />
      <Route path="/products/:id" element={<ProductDetail />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
    </Route>
  )
)
