import React from "react"

// Components
import { ProductHeroSection, ProductStats } from "components"

export const ProductDetailSection = ({ details }) => {
  return (
    <div className={`gap-y-8 flex flex-col`}>
      <ProductHeroSection
        images={details?.images}
        title={details?.title}
        description={details?.description}
      />

      <ProductStats stats={details?.stats} features={details?.keyFeatures}/>
    </div>
  )
}
