import React, { useEffect, useState } from "react"

// Components
import { ProductDetailSection } from "components"

// Router
import { useLocation } from "react-router-dom"

// Products
import { products } from "data"

export const ProductDetail = () => {
  const { pathname } = useLocation()
  const id = pathname.split("/")[2]
  const [details, setDetails] = useState([])

  const fetchDetails = () => {
    if (products) {
      const filtered = products?.filter((product) => product?.id === id)

      if (filtered[0]?.id) {
        setDetails(filtered[0])
      }
    }
  }

  useEffect(() => {
    fetchDetails()
  }, [])

  return (
    <div className="w-full">
      <ProductDetailSection details={details} />
    </div>
  )
}
