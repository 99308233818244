import { useState } from "react"

// Emailjs
import emailjs from "@emailjs/browser"

// Components
import { SectionHeader } from "components"

export const ContactSection = ({ className = "" }) => {
  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [notification, setNotification] = useState(false)

  const templateParams = {
    from_name: data.name,
    from_email: data.email,
    to_name: "GreenServe",
    message: data.message,
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setError(null)
    setNotification(false)

    setLoading(true)
    emailjs
      .send(
        process.env.REACT_APP_YOUR_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_YOUR_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_YOUR_EMAILJS_PUBLIC_KEY
      )
      .then(() => {
        setData({ name: "", email: "", message: "" })
        setLoading(false)
        setNotification(true)
      })
      .catch((err) => {
        setError(err.text)
        setLoading(false)
      })
  }

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value })
  }

  return (
    <div className={`isolate gap-y-8 ${className}`}>
      <SectionHeader title="Contact us" caption="Get In Touch" />

      <form
        onSubmit={handleSubmit}
        className="mx-auto max-w-sm md:max-w-lg lg:max-w-2xl text-center"
      >
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <label
              htmlFor="name"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="name"
                id="name"
                required
                value={data.name}
                autoComplete="name"
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 outline-none py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="email"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Email
            </label>
            <div className="mt-2.5">
              <input
                type="email"
                name="email"
                required
                id="email"
                autoComplete="email"
                value={data.email}
                onChange={handleChange}
                className="block w-full rounded-md outline-none border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Message
            </label>
            <div className="mt-2.5">
              <textarea
                name="message"
                required
                id="message"
                rows={4}
                onChange={handleChange}
                value={data.message}
                className="block w-full rounded-md outline-none border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
        <div className="mt-10">
          <button
            type="submit"
            name="Let's talk"
            className="block w-full rounded-md bg-primary px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
          >
            {loading ? "Sending ..." : "Let's talk"}
          </button>

          {!loading && notification && (
            <div className="mt-4 text-sm text-green-800 font-semibold">
              ✅ Email Sent !
            </div>
          )}

          {!loading && error && (
            <div className="mt-4 text-sm text-red-800 font-semibold">
              Error: {error} !
            </div>
          )}
        </div>
      </form>
    </div>
  )
}
