export const products = [
  {
    id: "s4parts",
    title: "S4 Parts",
    caption: "A Mobile Application To Sell And Buy Auto Parts.",
    description:
      "Welcome to S4Parts, the innovative mobile app that revolutionizes the way you buy and sell auto parts. Whether you're a car enthusiast, a DIY mechanic, or a professional in the automotive industry, S4Parts is your go-to platform for a seamless and efficient auto parts marketplace experience.",

    iconSrc: "assets/wheel-img.png",
    imageURL: "https://i.imgur.com/ick0icL.jpg",
    images: [
      "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80",
      "https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80",
      "https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=396&h=528&q=80",
      "https://images.unsplash.com/photo-1670272504528-790c24957dda?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=left&w=400&h=528&q=80",
      "https://images.unsplash.com/photo-1670272505284-8faba1c31f7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80",
    ],
    stats: [
      { label: "Transactions every 24 hours", value: "100 million" },
      { label: "Assets under holding", value: "$119 trillion" },
      { label: "New users annually", value: "46,000" },
    ],
    keyFeatures:[
        {
          topic: "Intuitive User Interface",
          description: "Our user-friendly interface ensures a smooth navigation experience." 
        },

        {
          topic: "Competitive Prices",
          description: "Find the best deals on both new and used auto parts. S4Parts connects you with sellers offering competitive prices, helping you save money on your automotive needs." 
        },

        {
          topic: "Sell with Ease",
          description: "Easily list your spare parts for sale within minutes. Provide detailed descriptions, set competitive prices, and connect with potential buyers effortlessly." 
        },

        {
          topic: "Real-time Notifications",
          description: "Stay informed with real-time notifications on new listings, offers, and messages. Never miss out on a great deal or an opportunity to sell your parts quickly." 
        },

        {
          topic: "Secure Transactions",
          description: "Our secure payment gateway ensures safe and reliable transactions, giving you peace of mind when buying or selling auto parts through S4Parts." 
        },
    ],
    conclusion: "S4Parts is not just a mobile app; it's your gateway to a thriving community of auto enthusiasts and a hassle-free marketplace for buying and selling auto parts. Download the app today and experience the future of automotive commerce.",
    download: "Download S4Parts on the App Store and Google Play",
    download_link: "",
  },
  {
    id: "s4macs",
    title: "S4 Mobile Accessories",
    caption: "A mobile application to sell and buy Mobiles and Mobile Accessories.",
    description:
      "Welcome to S4macs, the cutting-edge mobile app designed to transform the way you buy and sell mobile phones and accessories. Whether you're looking to upgrade your device or declutter by selling your old gadgets, S4macs is your go-to platform for a hassle-free and efficient mobile marketplace experience.",
    iconSrc: "assets/phone-img.png",
    imageURL: "https://i.imgur.com/jGlndxS.jpg",
    images: [
      "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80",
      "https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80",
      "https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=396&h=528&q=80",
      "https://images.unsplash.com/photo-1670272504528-790c24957dda?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=left&w=400&h=528&q=80",
      "https://images.unsplash.com/photo-1670272505284-8faba1c31f7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80",
    ],
    stats: [
      { label: "Transactions every 24 hours", value: "44 million" },
      { label: "Assets under holding", value: "$119 trillion" },
      { label: "New users annually", value: "46,000" },
    ],

    keyFeatures:[
      {
        topic: "Intuitive User Interface",
        description: "Our user-friendly interface ensures a smooth navigation experience." 
      },

      {
        topic: "Competitive Prices",
        description: "Find the best deals on both new and used auto parts. S4Parts connects you with sellers offering competitive prices, helping you save money on your automotive needs." 
      },

      {
        topic: "Sell with Ease",
        description: "Easily list your mobiles and accessories for sale within minutes. Provide detailed descriptions, set competitive prices, and connect with potential buyers effortlessly." 
      },

      {
        topic: "Real-time Notifications",
        description: "Stay informed with real-time notifications on new listings, offers, and messages. Never miss out on a great deal or an opportunity to sell your mobile phones and accessories quickly." 
      },

      {
        topic: "Secure Transactions",
        description: "Our secure payment gateway ensures safe and reliable transactions, giving you peace of mind when buying or selling mobile phones and accessories through S4macs." 
      },
    ],
    conclusion: "S4macs isn't just an app—it's your gateway to a thriving community of mobile enthusiasts and a hassle-free marketplace for buying and selling mobiles and accessories. Download the app today and experience the future of mobile commerce.",
    download: "Download S4macs on the App Store and Google Play",
    download_link: "",
  },
]

