import React from "react"

// Components
import {
  AboutSection,
  ContactSection,
  HeroSection,
  ProductSection,
} from "components"

export const App = () => {
  return (
    <div className="w-full">
      <HeroSection />
      <AboutSection />
      {/* <ProductSection /> */}
      <ContactSection className="mt-8" />
    </div>
  )
}
