import React from "react"

// Components
import { ProductCard, SectionHeader } from "components"

// Data
import { products } from "data"

export const ProductSection = ({ className = "" }) => {
  return (
    <div className={`gap-y-8 flex flex-col ${className}`}>
      <SectionHeader title="Products" caption="We Provide Better" />

      <div className="flex flex-wrap md:flex-row md:gap-x-8 gap-y-6 items-center md:items-start justify-center px-[40px] mt-[20px] flex-col text-white">
        {products &&
          products?.map(
            ({ id, title, description, caption, iconSrc, imageURL }) => (
              <ProductCard
                key={id}
                id={id}
                title={title}
                description={description}
                iconSrc={iconSrc}
                imageURL={imageURL}
                caption={caption}
              />
            )
          )}
      </div>
    </div>
  )
}
