import React from "react"

export const LogoText = () => {
  return (
    <div className="text-base">
      <span className="text-green-700 font-bold">Green</span>
      <span className="font-medium text-gray-900">Serve</span>
    </div>
  )
}
