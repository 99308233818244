import React from "react"

// Components
import { LogoText } from "components"

export const Footer = () => {
  return (
    <footer
      id="footer"
      className="px-[40px] mt-[80px] border-t border-opacity-20 border-gray-800 w-full"
    >
      <div className="footer container h-[80px] flex justify-between items-center max-w-[1800px] mx-auto">
        <LogoText />
        <div>
          <img width={50} height={50} src="assets/logo-img.png" alt="logo" />
        </div>
      </div>
    </footer>
  )
}
