import React from "react"

// Components
import { ContactSection } from "components"

export const ContactUs = () => {
  return (
    <div className="w-full p-[40px] max-w-[1800px] mx-auto gap-10">
      <ContactSection />

      <div className="mx-auto max-w-sm md:max-w-lg lg:max-w-2xl mt-[70px] flex flex-col lg:flex-row lg:gap-x-4 gap-y-4">
        <div className="drop-shadow-xl text-sm text-center gap-y-2 flex flex-col border border-primary border-opacity-30 shadow-md w-full lg:flex-1 p-[20px] rounded-lg">
          <div className="font-medium text-lg">Phone</div>
          <div>+971-50 933 6697</div>
        </div>
        <div className="drop-shadow-xl text-sm text-center gap-y-2 flex flex-col border border-primary border-opacity-30 shadow-md w-full lg:flex-1 p-[20px] rounded-lg">
          <div className="font-medium text-lg">Address</div>
          <div>Bussiness Center 1, M Floor, Nad AL Sheba, Dubai, UAE</div>
        </div>
      </div>
    </div>
  )
}
