import React from "react"

export const HeroSection = ({ className = "" }) => {
  return (
    <div
      style={{ background: "linear-gradient(90deg, #193c0d, #637462)" }}
      className={`p-[40px] w-full ${className}`}
    >
      <div
        className="flex max-w-[1800px] mx-auto flex-col gap-y-10 lg:flex-row py-[70px]"
        id="home"
      >
        <div className="flex flex-1 justify-center items-start flex-col mr-[60px]">
          <h1 className="text-white text-4xl leading-[100%] lg:text-6xl lg:leading-[100%] -tracking-[0.04em] font-bold">
            Reshaping Ecommerce to increase coverage and sustainability
          </h1>
        </div>

        <div className="flex-1 flex justify-center items-center">
          <img
            width={400}
            height={400}
            src="/assets/main_banner.jpg"
            alt="banner"
            className="w-full h-full rounded-lg"
          />
        </div>
      </div>
    </div>
  )
}
