import React from "react"

// Components
import { SectionHeader } from "components"

export const AboutSection = ({ className = "" }) => {
  return (
    <div
      className={`p-[40px] max-w-[1800px] mx-auto gap-y-8 flex flex-col ${className}`}
    >
      <SectionHeader title="About us" caption="Who we are" />

      {/* Content With Image */}
      <div className="flex flex-col lg:flex-row justify-center items-center gap-y-10 lg:gap-x-10">
        <div className="flex-1 w-full flex justify-center items-center">
          <img
            src="/assets/aboutus-img.webp"
            width={400}
            height={400}
            alt="people discussing the website"
            className="rounded-lg w-full lg:w-6/7"
          />
        </div>

        <div className="flex-1 w-full flex flex-col justify-center items-center lg:items-start">
          <p className="font-normal text-gray-800 max-w-xl text-base leading-[140%] lg:leading-[150%] lg:text-lg">
            At GreenServe, we are driven by a vision of sustainability and the
            immense potential of e-commerce. We firmly believe that the
            expanding reach of e-commerce can be harnessed to make a positive
            impact on the environment and society. Our commitment lies in taking
            e-commerce to the next level, where it becomes a force for good,
            accessible to everyone By aligning our bussiness practices with
            sustainable principles and fostering innovation in e-commerce, we
            aim to create a future where the convenience of online shopping
            coexists harmoniously with a healthier planet. Join us on this
            journey towards a more sustainable and inclusive e-commerce
            experience for all.
          </p>
        </div>
      </div>
    </div>
  )
}
