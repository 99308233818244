import React from "react"

// Router
import { Link } from "react-router-dom"

export const ProductCard = ({
  id,
  title,
  description,
  caption,
  imageURL,
  iconSrc,
}) => {
  return (
    <Link
      to={`${id}`}
      className="min-w-sm max-w-xs md:max-w-[270px] min-h-[350px] bg-cover flex flex-col p-[50px] rounded-lg gap-y-3"
      style={{
        backgroundImage: `linear-gradient(60deg, rgba(41, 50, 60, 0.9), rgba(72, 85, 99, 0.9)),
          url('${imageURL}')`,
      }}
    >
      <div className="icon h-[50px] max-h-[50px] w-[50px] max-w-[50px]">
        <img width={400} height={400} src={iconSrc} alt="icon" />
      </div>
      <h2 className="text-2xl font-medium uppercase mt-8 min-h-[60px]">
        {title}
      </h2>
      <p className="text-base capitalize text-gray-100 leading-snug">
        {caption}
      </p>
    </Link>
  )
}
