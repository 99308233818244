// Layout
import { Footer, Navbar } from "theme/layout"

export const RootLayout = ({ children }) => {
  return (
    <div className="min-h-screen h-full w-full flex flex-col justify-between">
      <Navbar />
      <div className="flex h-full">{children}</div>
      <Footer />
    </div>
  )
}
