import React, { useState } from "react"

// Router
import { Link, useLocation } from "react-router-dom"

// Components
import { LogoText } from "components"

export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const location = useLocation()

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <nav className="md:section_padding border-gray-800 border-b ">
      <div className="flex items-start justify-between w-full py-[10px] px-[20px] mx-auto max-w-[1800px]">
        <div className="flex items-center justify-between w-full">
          <Link to="/" className="flex items-center">
            <img width={50} height={50} src="assets/logo-img.png" alt="logo" />
            <LogoText />
          </Link>

          <button className="lg:hidden" name="Hamburger" onClick={toggleMenu}>
            {isMenuOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                width="23"
                height="20"
                viewBox="0 0 23 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.5 0.5H22.5V3.66667H0.5V0.5ZM6 8.41667H22.5V11.5833H6V8.41667ZM12.875 16.3333H22.5V19.5H12.875V16.3333Z"
                  fill="black"
                />
              </svg>
            )}
          </button>
          <div
            className={`hidden lg:flex h-[40px] w-full max-w-[500px] justify-end items-center font-medium`}
          >
            <Link
              to="/"
              className={`text-[#2e2e45] hover:text-gray-900 hover:underline w-full ${
                location.pathname === "/"
                  ? "font-medium underline text-primary"
                  : ""
              }`}
            >
              Home
            </Link>

            <Link
              to="/products"
              className={`text-[#2e2e45] hover:text-gray-900 hover:underline w-full ${
                location.pathname === "/products"
                  ? "font-medium underline text-primary"
                  : ""
              }`}
            >
              Products
            </Link>

            <Link
              to="/about-us"
              className={`text-[#2e2e45] hover:text-gray-900 hover:underline w-full ${
                location.pathname === "/about-us"
                  ? "font-medium underline text-primary"
                  : ""
              }`}
            >
              About Us
            </Link>

            <Link
              to="/contact-us"
              className={`text-[#2e2e45] hover:text-gray-900 hover:underline w-full ${
                location.pathname === "/contact-us"
                  ? "font-medium underline text-primary"
                  : ""
              }`}
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
      <div
        className={`${
          isMenuOpen ? "flex lg:hidden" : "hidden"
        } gap-y-2 flex-col justify-center items-center font-medium`}
      >
        <Link
          to="/"
          className={`block h-[40px] text-[#2e2e45] hover:text-gray-900 hover:underline w-full text-center ${
            location.pathname === "/"
              ? "font-medium underline text-primary"
              : ""
          }`}
          onClick={() => setIsMenuOpen(false)}
        >
          Home
        </Link>

        <Link
          to="/products"
          className={`block h-[40px] text-[#2e2e45] hover:text-gray-900 hover:underline w-full text-center ${
            location.pathname === "/products"
              ? "font-medium underline text-primary"
              : ""
          }`}
          onClick={() => setIsMenuOpen(false)}
        >
          Products
        </Link>

        <Link
          to="/about-us"
          className={`block h-[40px] text-[#2e2e45] hover:text-gray-900 hover:underline w-full text-center ${
            location.pathname === "/about-us"
              ? "font-medium underline text-primary"
              : ""
          }`}
          onClick={() => setIsMenuOpen(false)}
        >
          About Us
        </Link>

        <Link
          to="/contact-us"
          className={`block h-[40px] text-[#2e2e45] hover:text-gray-900 hover:underline w-full text-center ${
            location.pathname === "/contact-us"
              ? "font-medium underline text-primary"
              : ""
          }`}
          onClick={() => setIsMenuOpen(false)}
        >
          Contact Us
        </Link>
      </div>
    </nav>
  )
}
