import React from "react"

// Components
import { ProductSection } from "components"

export const Products = () => {
  return (
    <div className="w-full">
      <ProductSection />
    </div>
  )
}
